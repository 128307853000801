$.fn.createGallery = function () {
    let $el = $(this);

    $el.lightGallery({
        thumbnail: true,
        selector: "[data-gallery-item]",
        exThumbImage: 'data-exthumbimage',
        fullScreen: false,
        zoom: true,
        actualSize: false,
        hash: false,
        download: true,
        autoplay: false,
        autoplayControls: false
    });
};