(function($){
    const $fn = $(".comp_visual");

    if ($fn.length) {
        cssLoaded(function() {
            let $slider = $fn.find('[data-visual-slider]'),
                $caption = $fn.find('[data-visual-caption]'),
                autoplay = $slider.data("visual-slider");

            if($caption.children().length > 1) {
                $caption.flickity({
                    contain: true,
                    pageDots: false,
                    prevNextButtons: true,
                    wrapAround: true,
                    autoPlay: autoplay,
                    adaptiveHeight: true,
                    pauseAutoPlayOnHover: true
                });
            }

            if($slider.children().length > 1) {
                $slider.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: false,
                    pageDots: false,
                    wrapAround: true,
                    contain: true,
                    sync: $caption[0],
                    prevNextButtons: false,
                });
            }
        });

        $fn.on("click","[data-scroll]",function () {
            $('html, body').animate({
                scrollTop: $fn.next("section").offset().top - $("#layout_header").innerHeight()
            }, 500);
        });
    }
})(jQuery);