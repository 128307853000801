(function ($) {
    let $fn = $(".comp_tabs");

    if ($fn.length) {
        let $gallery = $fn.find("[data-gallery-list]"),
            $nav = $fn.find(".wrp_comp_nav"),
            $links = $nav.find("[data-ajax]");

        if($gallery.length){
            $gallery.createGallery();
        }

        $nav.on("click","[data-ajax]",function (e) {
            e.preventDefault();
            $links.filter(".mod--active").removeClass("mod--active");
            $(this).addClass("mod--active");
            //AJAX na vymenu stranek
        });

        $nav.on("click","[data-submenu]",function () {
            $(this).toggleClass("mod--active");
        });

        $nav.on("change","[data-page-select]",function () {
            console.log("AJAX go to " + $(this).val());
            //AJAX na vymenu stranek

            $('html, body').animate({
                scrollTop: $fn.find(".wrp_comp_content").offset().top - $("#layout_header").innerHeight()
            }, 500);
        })
    }
})(jQuery);