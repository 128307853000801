(function($) {
    fn_nl_lib_anchor();

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
        $(e.target).one('load', function() {
            if ($(this).parent().hasClass("lib--lazyload")) {
                $(this).parent().removeClass("lib--lazyload").addClass("lib--lazyloaded");
            }
        });
    });

    nl_lib_dialog.init(function(){
        doc.find(".lib--dialog .part_ui_btn").nl_lib_ripple();
    });
    $(".part_ui_btn").nl_lib_ripple();

    doc.on('click', '[href].ajax', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
            }
        });
    });

    doc.on('submit', 'form.ajax', function (e) {
        e.preventDefault();
        let frm = $(this),
            formData = new FormData($(this)[0]);
            
        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
            }
        });
        
        
    });

    doc.on('click', '[data-changegallery]', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
                sr.sync();
                $('#snippet-gallery').createGallery();
            }
        });
    });
    
    doc.on('click', '[data-ajax]', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
                sr.sync();
                $('#snippet-services').createGallery();
            }
        });
    });
    
    doc.on('change', '[data-page-select]', function (e) {
        let link = '?do=changeservices&pageId=' + $(this).val();
        $.ajax({
            url: link,
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
                sr.sync();
            }
        });
    });
    
    let picture = document.createElement('picture'),
        strObj = '' + picture;
    if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
    {
        $.getScript(cdnjs.picturefill);
    }
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        html.addClass("ie11");
    }
    if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) {
        html.addClass("safari");
    }
    
    $('#snippet-contactform').antiSpam();


    if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
        $.getScript(cdnjs.cookieconsent);
    }
})(jQuery);